<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Reportes</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Productos</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-6 lg:px-8">
    <div
      class="
        flex
        align-items-center
        flex-column
        lg:justify-content-center lg:flex-row
      "
    >
      <h4><strong>REPORTE GENERAL DE PRODUCTOS</strong></h4>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-3">
            <label for="unidadmedida"><strong>LABORATORIO: </strong> </label>
            <Dropdown
              v-model="laboratorioSeleccionado"
              :options="laboratorios"
              optionLabel="nombre"
              :filter="true"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-3">
            <label for=""><strong>PROVEEDOR: </strong></label>
            <AutoComplete
              :dropdown="true"
              field="nombre_completo"
              v-model="proveedorSelected"
              :suggestions="proveedoresFiltrado"
              :filter="true"
              @complete="buscarProveedor($event)"
              placeholder="Buscar Proveedor"
            />
          </div>
          <div class="field col-12 md:col-4">
            <label for=""><strong>NOMBRE PRODUCTO:</strong></label>
            <AutoComplete
              :dropdown="true"
              field="descripcion"
              v-model="productoSelected"
              :suggestions="productosListadoFiltrado"
              @complete="buscarProducto($event)"
              placeholder="Escriba Nombre Producto"
            >
              <template #item="slotProps">
                <div>
                <strong>{{ slotProps.item.descripcion }}</strong
                ><span
                  ><strong> --- </strong
                  ><!-- </span>
                      {{ slotProps.item.fabricas.nombre_proveedor
                      }}<span><strong> ---</strong> --></span
                >
                {{ slotProps.item.fabrica_nombre
                }}<span><strong> ---</strong></span>
                <span :class="stockClass(slotProps.item.stock[0].cantidad)"
                  ><strong>{{ slotProps.item.stock[0].cantidad }}</strong></span
                >
              </div>
              </template>
            </AutoComplete>
          </div>
          <div class="field col-12 md:col-2">
            <label for="fabrica_id"><strong>UNIDAD DE MEDIDA: </strong> </label>
            <Dropdown
              v-model="unidadmedidaSeleccionado"
              :options="unidadmedidas"
              :filter="true"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-4">
            <label for="precio_minimo"><strong>PRECIO INICIAL: </strong></label>
            <InputNumber
              id="precio_minimo"
              v-model="precio_minimo"
              :showButtons="true"
              :min="0"
            />
          </div>
          <div class="field col-12 md:col-4">
            <label for="precio_maximo"><strong>PRECIO FINAL: </strong></label>
            <InputNumber
              id="precio_maximo"
              v-model="precio_maximo"
              :showButtons="true"
              :min="0"
            />
          </div>
          <div class="field col-12 md:col-4">
            <label for="acciones"><strong>ACCIONES: </strong></label>
            <Button
              label="APLICAR FILTROS"
              icon="pi pi-search"
              class="p-button-primary mr-2"
              v-tooltip.top="'Buscar Productos'"
              :loading="enviando"
              :disabled="enviando"
              @click="BuscarFiltro"
            />
          </div>
        </div>
        </div>
        <div class="mt-4">
          <DataTable
            ref="reporte_ventas"
            key="id"
            :value="productos"
            responsiveLayout="scroll"
            :loading="enviando"
            class="p-datatable-sm"
            :filters="buscar"
            responsive="true"
            stripedRows
            showGridlines
            :rows="10"
            :paginator="true"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 100, 500, 1000, 5000, 10000]"
            currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Reportes de Productos"
          >
            <template #header>
              <div
                class="
                  flex flex-column
                  md:flex-row md:justify-content-end md:align-items-end
                "
              >
                <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="buscar['global'].value"
                    placeholder="Buscar Productos..."
                  />
                </span>
                <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                  <Button
                    v-if="'R_Productos DescargarPDF' in auth.user.permissions"
                    label="Imprimir PDF"
                    icon="pi pi-file-pdf"
                    class="p-button-outlined p-button-danger mr-2"
                    v-tooltip.top="'Imprimir Reporte PDF'"
                    :loading="generandoPDF"
                    :disabled="generandoPDF"
                    @click="generarPDF"
                  />
                </span>
                <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                  <Button
                    v-if="'R_Productos DescargarEXCEL' in auth.user.permissions"
                    label="Exportar Excel"
                    icon="pi pi-file-excel"
                    class="p-button-outlined p-button-success mr-1"
                    v-tooltip.top="'Imprimir Reporte Excel'"
                    :loading="exportando"
                    :disabled="exportando"
                    @click="exportProductos"
                  />
                </span>
              </div>
            </template>
            <template #loading>
              <div class="flex align-items-center justify-content-center">
                <ProgressSpinner />
              </div>
            </template>
            <template #empty>
              <span class="p-invalid">
                Debe aplicar filtros para ver resultados en la tabla!</span
              >
            </template>
            <Column header="#">
              <template #body="slotProps">
                {{ 1 + slotProps.index }}
              </template>
            </Column>
            <Column field="id" header="CÓDIGO PRODUCTO" :sortable="true">
            </Column>
            <Column field="nombre_proveedor" header="PROVEEDOR"> </Column>
            <Column field="nombre_laborario" header="LABORATORIO">
              {{ data.nombre_laborario }}
            </Column>
            <Column field="nombre_unidadmedida" header="UNIDA DE MEDIDA">
              {{ data.nombre_unidadmedida }}
            </Column>
            <Column field="descripcion" header="DESCRIPCIÓN/PRODUCTO"> </Column>
            <Column field="precio_sugerido" header="PRECIO SUGERIDO">
              <template #body="{ data }">
                {{ convertirNumeroGermanicFormat(data.precio_sugerido) }}
              </template>
            </Column>
          </DataTable>
        </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "@/service/ProductService";
import UnidadmedidaService from "@/service/UnidadmedidaService";
import LaboratorioService from "@/service/LaboratorioService";
import ProveedorService from "@/service/ProveedorService";
import { useAuth } from "@/stores";

export default {
  data() {
    return {
      enviando: false,
      generandoPDF: false,
      exportando: false,
      unidadmedidas: [{ id: 0, nombre: "TODAS" }],
      unidadmedidaSeleccionado: 0,
      laboratorios: [{ id: 0, nombre: "TODAS" }],
      laboratorioSeleccionado: 0,
      precio_minimo: 0,
      precio_maximo: 0,
      productos: [],
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      proveedorSelected: null,
      proveedoresFiltrado: [],
      productoSelected: null,
      productosListadoFiltrado: [],
      stockClass: (data) => {
        if (data.cantidad <= 0) {
          return "text-red-500";
        } else if (data.cantidad <= 5) {
          return "text-yellow-500";
        } else {
          return "text-green-500";
        }
      },
    };
  },
  productoService: null,
  unidadmedidaService: null,
  laboratorioService: null,
  proveedorService: null,
  auth: null,
  created() {
    this.productoService = new ProductService();
    this.unidadmedidaService = new UnidadmedidaService();
    this.laboratorioService = new LaboratorioService();
    this.proveedorService = new ProveedorService();
    this.auth = useAuth();
  },
  mounted() {
    this.cargarUnidadmedidas();
    this.cargarLaboratorios();
  },
  methods: {
    buscarProveedor(event) {
      setTimeout(() => {
        this.proveedorService
          .buscarXNombre({ texto: event.query })
          .then((response) => {
            this.proveedoresFiltrado = [...response.proveedores];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    buscarProducto(event) {
      setTimeout(() => {
        this.productoService
          .buscarXNombre({ texto: event.query })
          .then((response) => {
            this.productosListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    exportProductos() {
      if (this.productos.length > 0) {
        let datos = {
          unidadmedida: this.unidadmedidaSeleccionado,
          fabrica: this.laboratorioSeleccionado,
          precio_minimo: this.precio_minimo,
          precio_maximo: this.precio_maximo,
          proveedor_id: this.proveedorSelected
            ? this.proveedorSelected.id
            : null,
            producto : this.productoSelected ? this.productoSelected.id : null
        };
        this.productoService.exportarProductos(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Exportación Exitosa",
            detail: "Se ha exportado correctamente los productos",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    generarPDF() {
      if (this.productos.length > 0) {
        this.generandoPDF = true;
        let datos = {
          fabrica: this.laboratorioSeleccionado,
          unidadmedida: this.unidadmedidaSeleccionado,
          precio_minimo: this.precio_minimo,
          precio_maximo: this.precio_maximo,
          proveedor_id: this.proveedorSelected
            ? this.proveedorSelected.id
            : null,
            producto : this.productoSelected ? this.productoSelected.id : null
        };
        this.productoService.imprimirPDF(datos).then(() => {
          this.generandoPDF = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se generó el PDF correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para generar el reporte",
          life: 3000,
        });
      }
    },
    BuscarFiltro() {
      this.productos = [];
      this.enviando = true;
      this.cargarReporteProductos();
    },
    cargarReporteProductos() {
      let datos = {
        unidadmedida: this.unidadmedidaSeleccionado,
        fabrica: this.laboratorioSeleccionado,
        precio_minimo: this.precio_minimo,
        precio_maximo: this.precio_maximo,
        proveedor_id: this.proveedorSelected ? this.proveedorSelected.id : null,
        producto : this.productoSelected ? this.productoSelected.id : null
      };
      this.productoService
        .filtrarProductos(datos)
        .then((response) => {
          this.productos = response.productos;
          this.enviando = false;
        })
        .catch((error) => {
          this.enviando = false;
          console.log(error);
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE").format(numero);
    },
    cargarUnidadmedidas() {
      this.unidadmedidaService
        .getUnidadmedidasAll()
        .then((data) => {
          data.forEach((unidadmedida) => {
            this.unidadmedidas.push(unidadmedida);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarLaboratorios() {
      this.laboratorioService
        .getAllLaboratorios()
        .then((data) => {
          data.fabricas.forEach((laboratorio) => {
            this.laboratorios.push(laboratorio);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-fondo {
  background-color: #7bffae;
}
</style>
